import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, HostListener } from '@angular/core';
import { CaptchaService } from '../captcha.service';
import { CommService } from '../comm.service';
import { FormControl } from '@angular/forms';
import { RecaptchaModule } from 'angular-google-recaptcha';
@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss'],
  providers:[CaptchaService]
})
export class ContactusComponent implements OnInit {
  submitted = false;
  contactFormGroup: FormGroup;
  disabledSubmitButton: boolean = true;
  optionsSelect: Array<any>;
  locationmap="assets/locationmap.png";
  captchaResponse = false;

   myRecaptcha = new FormControl(false);
 
    onScriptLoad() {
        console.log('Google reCAPTCHA loaded and is ready for use!')
    }
 
    onScriptError() {
        console.log('Something went long when loading the Google reCAPTCHA')
    }

    verifyCallback() {
    
    alert('veify the Google reCAPTCHA');
    this.captchaResponse= true;
    
    }

  
    constructor(private fb: FormBuilder, private commService: CommService) {  }

      // convenience getter for easy access to form fields
      get cfg() { return this.contactFormGroup.controls; }


      ngOnInit() {
        this.contactFormGroup = this.fb.group({
        'contactFormName': ['', Validators.required],
        'contactFormEmail': ['', Validators.compose([Validators.required, Validators.email])],
        'contactFormPhone': ['', Validators.required],
        'contactFormSubjects': ['', Validators.required],
        'contactFormMessage': ['', Validators.required],
        'contactFormCopy': [''],
        });
     

        this.optionsSelect = [
          { value: 'Feedback', label: 'Feedback' },
          { value: 'Report a bug', label: 'Report a bug' },
          { value: 'Feature request', label: 'Feature request' },
          { value: 'Other stuff', label: 'Other stuff' },
          ];
          // set the captchaEndpoint property to point to  
  } 
        
      
        onSubmit() {
          console.log('onsubmit called');
          
          this.submitted = true;

        // stop here if form is invalid
        if (this.contactFormGroup.invalid) {
            alert('Information Missing, Please enter valid data');
            return;
        }
        // display form values on success
          console.log('SUCCESS!! :-)\n\n');
          console.log(this.captchaResponse);
    
          this.commService.sendMessage(this.contactFormGroup.value).subscribe(() => {
            alert('Your message has been sent.');
            this.onReset();
            this.disabledSubmitButton = true;
          }, error => {
            console.log('Error', error);
            alert('Sorry, We are unable to process your request now, kindly try after some time');
          });
        }

      onReset() {
        this.submitted = false;
        this.contactFormGroup.reset();
    }
        resolved(captchaResponse: string) {
          console.log(`Resolved captcha with response ${captchaResponse}:`);
        }

        
 /** 
   * Process the contact form on submit event. 
   */ 
  send(value, valid): void { 

    if (valid) { 

      let postData = { 
        /*
        name: value.name, 
        email: value.email, 
        subject: value.subject, 
        message: value.message,
        */ 
         }; 

     
    } 

  } 
} 

