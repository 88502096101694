import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(private meta: Meta) {
    this.meta.addTags([
      {name: 'description', content: '3D Model, 3D Printing Services in Hyderabad, 3D Printing Workshop, 3D Printing Training, 3D Design '},
      {name: 'author', content: 'Five Fingers Innovative Solutions'},
      {name: 'keywords', content: '3D Printing, Rapid prototype, RUP, 3D Model, 3D Visualization, Medical Model, 3D Training, 3D Workshop, Anatomical Model'}
    ]);
   }

  ngOnInit() {
  }

}
