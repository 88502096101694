import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-todopage',
  templateUrl: './todopage.component.html',
  styleUrls: ['./todopage.component.sass']
})
export class TodopageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
