import { Component } from '@angular/core';


@Component({
  selector: 'app-objectviewer',
  templateUrl: './objectviewer.component.html',
  styleUrls: ['./objectviewer.component.scss']
})
export class ObjectviewerComponent {
  title = 'stl-model-viewer basic example'

}