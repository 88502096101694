import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { environment} from '../environments/environment'
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

const API_URL = environment.apiUrl;
const httpOptions = {
  headers: new HttpHeaders({ 
    'Access-Control-Allow-Origin':'*'
    
  })
};

@Injectable({
  providedIn: 'root'
})
export class CommService {
  servResp: any;
 
  constructor(  private http: HttpClient) { }

  public sendMessage(message: any) :  Observable <any> {
    console.log (message);
    return this.http
    .post(API_URL + '/emailQuery', message, httpOptions)
    .catch(this.handleError);
}
  
   private handleError (error:any) {
    console.error('commService::handleError', error);
    return Observable.throw(error);

  
}
}
